<template>
    <div class="flex p-3 mb-3 relative">
        <div class="right-0 absolute">
            <el-input ref="searchInput" class="w-64" placeholder="Filter" @keyup.native="inputChange" v-model="query" clearable />
            <StatusSelect @change="searchParametersChanged" />
            <TestCompanySelect @change="searchParametersChanged" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
export default {
    data() {
        return {
            query: "",
            timer: null,
        };
    },

    components: {
        StatusSelect: () => import("./StatusSelect.vue"),
        TestCompanySelect: () => import("./TestCompanySelect.vue"),
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        searchParametersChanged() {
            this.$emit("searchInputChange");
        },

        assignInitValues() {
            const { search } = queryString.parse(location.search);
            this.query = search;
        },

        inputChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.updateUrl();
                this.$emit("searchInputChange");
            }, 700);
        },

        updateUrl() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.search = this.query;
            this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
